import React from "react";

import Page from "lib/@cms/components/layout/Page";
import PageHeader from "lib/@cms/components/cms/PageHeader";
import ContentBox from "lib/@cms/components/shared/ContentBox";
import Button from "lib/@cms/components/primitive/Button";
import Divider from "lib/@cms/components/primitive/Divider";

function AnnualReportPage() {
  return (
    <Page pathname="annual-report">
      {data => {
        return (
          <React.Fragment>
            <PageHeader
              variant={PageHeader.variant.GRADIENTS}
              color={PageHeader.color.PRIMARY}
              data={data.PageHeaderGradients}
            />
            <div className="tw-container">
              <ContentBox>
                {data.Texts?.map((element, index) => (
                  <p key={`ContentBox-Texts-${index}`} className="tw-mb-6">
                    {element.item}
                  </p>
                ))}
              </ContentBox>
              .
              <Button is="a" href={data.ButtonPdf?.pdf ? data.ButtonPdf?.pdf?.url : "/404"}>
                {data.ButtonPdf.label}
              </Button>
              <Divider className="tw-my-6" />
              <ContentBox title={data.Reports?.title}>
                <div className="tw-flex tw-flex-wrap">
                  {data.Reports?.pdf?.map((item, index) => {
                    return (
                      <div
                        key={`ContentBox-Reports-${index}`}
                        className="tw-py-2 tw-w-1/3 lg:tw-w-1/5 tw-flex tw-justify-center"
                      >
                        <Button
                          is="a"
                          href={item.pdf ? item.pdf.url : "/404"}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {item.label}
                        </Button>
                      </div>
                    );
                  })}
                </div>
              </ContentBox>
              <Divider className="tw-my-16 sm:tw-my-24" />
            </div>
          </React.Fragment>
        );
      }}
    </Page>
  );
}

export default AnnualReportPage;
